<template>
  <div class="skeleton-container">
    <div>
      <q-skeleton height="30px" width="50%" type="text" animation="fade" square />
      <div class="skeleton-grid">
        <div class="skeleton-grid__left">
          <q-skeleton class="skeleton-grid__left-media q-mb-lg" square />
          <q-skeleton
            v-for="i in 3"
            :key="i"
            class="text-h4 text-secondary"
            type="text"
            :width="`${100 - (i - 1) * 20}%`"
            animation="fade"
          />
          <div class="skeleton-amenities-container">
            <div v-for="(amenity, i) in amenities" :key="i" class="skeleton-amenity">
              <q-icon :name="amenity" size="24px" class="q-ma-sm text-primary-3" />
              <q-skeleton class="text-secondary" type="text" width="50px" />
              <q-separator v-if="i < 3" class="q-mx-xl" vertical />
            </div>
          </div>
          <q-skeleton type="QBtn" width="100%" height="48px" class="q-my-lg" />
          <q-separator class="q-my-lg" />
          <q-skeleton
            class="col-12 text-body1 text-accent q-mb-sm"
            height="40px"
            width="70%"
            type="text"
            animation="fade"
          />
          <q-skeleton
            v-for="i in 3"
            :key="i"
            class="text-h4 text-secondary"
            type="text"
            :width="`${100 - (i - 1) * 10}%`"
            animation="fade"
          />
          <div class="row q-mt-md">
            <q-skeleton
              class="col-12 text-body1 text-accent q-mb-sm"
              height="40px"
              width="70%"
              type="text"
              animation="fade"
            />
            <div v-for="i in 15" :key="i" class="col-6">
              <q-skeleton
                class="row text-body1 text-accent q-my-sm"
                type="text"
                width="60%"
                animation="fade"
              />
            </div>
          </div>
        </div>
        <div class="skeleton-grid__right">
          <q-skeleton class="q-mb-md" height="480px" square />
          <q-skeleton
            class="col-12 text-body1 text-accent q-mb-md"
            height="40px"
            width="50%"
            type="text"
            animation="fade"
          />
          <q-skeleton
            class="row text-body1 text-accent q-mb-md"
            type="text"
            width="60%"
            animation="fade"
          />
          <q-skeleton
            class="col-12 text-body1 text-accent q-mb-md"
            height="40px"
            width="50%"
            type="text"
            animation="fade"
          />
          <div class="row q-mb-md">
            <div v-for="i in 4" :key="i" class="col-6">
              <q-skeleton
                class="row text-body1 text-accent q-my-sm"
                type="text"
                width="90%"
                animation="fade"
              />
            </div>
          </div>
          <q-skeleton height="80px" square />
        </div>
      </div>
    </div>
    <div class="skeleton-grid-widget">
      <q-skeleton
        class="text-body1 text-accent"
        height="48px"
        width="100%"
        type="QBtn"
        animation="fade"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const amenities = ['bedrooms', 'size', 'parking', 'floor'];
</script>

<style lang="scss" scoped>
@use 'sass:map';

.skeleton-amenities-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 8px;
  margin-top: 1rem;

  .skeleton-amenity {
    display: flex;
    flex-wrap: nowrap;
  }
}

.skeleton-grid__left {
  width: calc(100% - 570px);

  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.skeleton-grid__left-media {
  height: 480px;

  @media (max-width: $breakpoint-sm) {
    height: 340px;
  }
}

.skeleton-grid__right {
  width: 570px;

  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.skeleton-container {
  max-width: 98.75rem;
  min-height: 300px;
  padding: 1rem 3.5rem;
  margin: 0 auto;

  @media (max-width: $breakpoint-sm) {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
}

.skeleton-grid {
  display: flex;
  gap: 1.5rem;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }
}

.skeleton-grid-widget {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: none;
  padding: 1rem;
  background: $white;
  border-radius: map.get($radius-sizes, lg) map.get($radius-sizes, lg) 0 0;
  box-shadow: 0 -4px 20px 10px rgb(219 225 245 / 35%);

  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}
</style>
