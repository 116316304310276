import { storeToRefs } from 'pinia';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import { useApiListing } from '@/composables/api/listing';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import { useUtmSource } from '@/composables/useUtmSource';
import { useStepsAuth } from '@/factories/formStepsFactory/auth';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import { CommercializationType } from '@/types';
import type { FactoryId, Step } from '@/types/formStepsFactory';

export const useStepsLpInterest = () => {
  const factoryId: FactoryId = 'lp-interest';

  const { storeInterest } = useApiListing();
  const { user } = storeToRefs(useAuthStore());
  const { listing } = storeToRefs(useListingStore());

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });
  const { getFormStepsAuth } = useStepsAuth();

  const getFormStepsLpInterest = () => {
    const authFormSteps = getFormStepsAuth();

    const { resolveUtmSource } = useUtmSource();

    const submit = async () => {
      const { getToken } = useCaptcha();

      const rToken = await getToken('submitInterest');

      const formData = reactive({
        contact: {
          firstName: user.value?.first_name || '',
          lastName: user.value?.last_name || '',
          email: user.value?.email || '',
          phone: user.value?.phone || '',
        },
        r_token: rToken,
        listingId: listing.value?.id || -1,
        utmSource: resolveUtmSource(),
        accepts_terms: true,
        newsletterOptIn: false,
      });

      let gtmEvent = '';

      if (listing.value!.commercializationType === CommercializationType.RENTAL) {
        gtmEvent = 'submit_interest_rent_success';
      } else {
        gtmEvent = 'submit_interest_sale_success';
      }

      return storeInterest(formData).then(() => {
        useGTM(gtmEvent);
      });
    };

    return computed<Step[]>(() => [
      ...authFormSteps.value,
      {
        component: () => FormStepperCallback,
        componentProps: {
          onMountedCallbackFn: submit,
          formInfoMsgPropsError: {
            qIconProps: {
              color: 'negative',
              name: 'warning',
              size: '5rem',
            },
            subtitle: { text: 'Error subtitle' },
            title: { text: 'Error title' },
          },
          formInfoMsgPropsSuccess: {
            qIconProps: {
              color: 'primary',
              name: 'success',
              size: '5rem',
            },
            subtitle: {
              text: t('subtitle.interestSuccess', { listingId: listing.value?.propertyCode }),
            },
            title: { text: t('title.interestSuccess') },
          },
        },
        formId: 'callback',
      },
    ]);
  };

  return { factoryId, getFormStepsLpInterest };
};
