import { computed, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Attribute } from '@/composables/attributesRenderer';
import { type SearchResultsItemProps, useSearchResultsItem } from '@/composables/searchResultsItem';
import { useTheme } from '@/composables/theme';
import translationsListingBadges from '@/i18n/translations/components/listingBadges.json';
import { toThousands } from '@/utils/number';

export const useVarPool = (_props: SearchResultsItemProps) => {
  const props = toRef(_props);

  const searchResultsItem = useSearchResultsItem(props.value as SearchResultsItemProps);

  const { t: tGlobal } = useI18n();
  const { t: tListingBadges } = useI18n(translationsListingBadges);
  const { icons, propertyCodePrefix } = useTheme();

  const badges = computed<Attribute[]>(() => [
    {
      key: 'new',
      value: tListingBadges('newlyListed'),
      visible: searchResultsItem.newlyListedVisible,
    },
    {
      key: 'hot',
      value: tListingBadges('hot'),
      visible: searchResultsItem.listingHasLabel('hot'),
    },
  ]);

  const aggregates = computed<Attribute[]>(() => [
    {
      icon: icons.size,
      key: 'size',
      value: toThousands(props.value.listing.size) + tGlobal('sqm'),
      visible: props.value.listing.size > 0,
    },
    {
      icon: icons.floor,
      key: 'floor',
      value: searchResultsItem.getFloorsText(false),
      visible: !!searchResultsItem.getFloorsText(true),
    },
    {
      icon: icons.bedrooms,
      key: 'bedrooms',
      value: searchResultsItem.bedrooms,
      visible: searchResultsItem.bedrooms > 0,
    },
    {
      icon: icons.parking,
      key: 'parking',
      value: searchResultsItem.parkingSpaces,
      visible: searchResultsItem.parkingSpaces > 0,
    },
  ]);

  const propertyCode = computed(() => {
    let propertyCodeLocal = `${tGlobal(propertyCodePrefix)}: ${props.value.listing?.propertyCode}`;

    const groupPropertyParentCode = props.value.listing?.groupPropertyParentCode;

    if (groupPropertyParentCode) {
      propertyCodeLocal += ` (${groupPropertyParentCode})`;
    }

    return propertyCodeLocal;
  });

  watch(
    searchResultsItem.elListingItemFavoritesBtnWidth,
    v => {
      document.documentElement.style.setProperty('--favorites-btn-width', v);
    },
    { immediate: true }
  );

  return {
    ...searchResultsItem,
    aggregates,
    badges,
    icons,
    propertyCode,
    tListingBadges,
  };
};
