import { computed } from 'vue';

import type { FactoryId, FormStepsFactory } from '@/types/formStepsFactory';

export const useStepsLpMortgage = () => {
  const factoryId: FactoryId = 'unsupported';

  const getFormStepsLpMortgage = (): FormStepsFactory => computed(() => []);

  return { factoryId, getFormStepsLpMortgage };
};
